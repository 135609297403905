import styled from "styled-components"

import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type PresetSectionProps = {
  hidden?: boolean
  children: React.ReactNode
  title: React.ReactNode
  description?: React.ReactNode
}

export function PresetSection({
  hidden,
  title,
  description,
  children,
}: PresetSectionProps) {
  if (hidden) return null

  return (
    <SectionBox>
      <MText variant="subtitle">{title}</MText>
      {description && <MText variant="bodyS">{description}</MText>}

      <SectionContentBox>{children}</SectionContentBox>
    </SectionBox>
  )
}

const SectionBox = styled.div`
  display: grid;
`

const SectionContentBox = styled.div`
  margin-top: ${spacing.L};
  display: grid;
  gap: ${spacing.M};
`
