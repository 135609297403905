import styled from "styled-components"

import { Switch } from "@material-ui/core"

import { PresetSection } from "src/components/SettingsProfiles/MonitoringPresets/MonitoringPresetDialogs/PresetSection"
import { MInfo } from "src/ui/Info/MInfo"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function PresetSectionToggle({
  title,
  description,
  state,
  setState,
  children,
  disabled = false,
  infoTooltipContents,
}: {
  title: string
  description?: string
  state: boolean
  setState: (b: boolean) => void
  disabled?: boolean
  children?: React.ReactNode
  infoTooltipContents?: React.ReactNode
}) {
  return (
    <PresetSection
      title={
        <ToggleContainer>
          <MText variant="subtitle">
            {title}{" "}
            {infoTooltipContents && (
              <InfoTooltip tooltip={infoTooltipContents} />
            )}
          </MText>

          <Switch
            disabled={disabled}
            checked={state === true}
            onChange={(ev) => setState(ev.target.checked)}
          />
        </ToggleContainer>
      }
      description={description}
    >
      {state && children}
    </PresetSection>
  )
}

const ToggleContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  gap: ${spacing.L} ${spacing.M};
`

function InfoTooltip({ tooltip }: { tooltip: React.ReactNode }) {
  return (
    <MInfo
      iconProps={{ type: "info" }}
      content={tooltip}
      wrapperProps={{ style: { display: "inline" } }}
    />
  )
}
