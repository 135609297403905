import styled from "styled-components"

import { PresetSettingCardNoiseAlert } from "src/components/SettingsProfiles/MonitoringPresets/MonitoringPresetCards/PresetSettingCardNoiseAlert"
import { PresetSettingCardNoiseThresholds } from "src/components/SettingsProfiles/MonitoringPresets/MonitoringPresetCards/PresetSettingCardNoiseThresholds"
import { NoiseAlertSettingCard } from "src/components/SettingsProfiles/NoiseAlertSettingCard"
import { useFetchProfiles } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { useFetchOrganizations } from "src/data/organizations/queries/organizationQueries"
import { TNoiseMonitoringPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type MonitoringPresetsNoiseSettingsProps = {
  hidden?: boolean
  presetData: TNoiseMonitoringPreset
}

export function MonitoringPresetsNoiseSettings({
  hidden,
  presetData,
}: MonitoringPresetsNoiseSettingsProps) {
  const { t, langKeys, tPlaceholder } = useTranslate()
  const variants = ["first_alert", "second_alert", "third_alert"] as const
  const { debug } = useFlags()

  //region Legacy profile hacks
  // TODO MON-891: Remove profile & org hacks
  const fetchOrganizations = useFetchOrganizations()
  const legacyProfileOrg = fetchOrganizations.data?.organizations.at(-1)
  const orgId = legacyProfileOrg?.id
  const fetchProfiles = useFetchProfiles({
    orgId,
    options: { enabled: !!orgId },
  })
  const legacyProfileResponse = fetchProfiles.data?.profiles[0]
  // NB: If the first org does not use legacy profiles, null will be returned
  if (!legacyProfileResponse || !legacyProfileOrg) return null
  //endregion Legacy profile & org hacks

  if (hidden) return null

  return (
    <MonitoringPresetsNoiseSettingsBox>
      <CardSection title={tPlaceholder("Noise thresholds")}>
        <PresetSettingCardNoiseThresholds presetData={presetData} />
      </CardSection>

      <CardSection title={t(langKeys.settings_noise_alerts_title)}>
        {variants.map((variant) => {
          return (
            <>
              <PresetSettingCardNoiseAlert key={variant} variant={variant} />

              {debug && (
                <NoiseAlertSettingCard
                  organizationResponse={legacyProfileOrg}
                  profileResponse={legacyProfileResponse}
                  variant={variant}
                />
              )}
            </>
          )
        })}
      </CardSection>
    </MonitoringPresetsNoiseSettingsBox>
  )
}

const MonitoringPresetsNoiseSettingsBox = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`

function CardSection({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) {
  return (
    <CardsSectionBox>
      <MText variant="heading2">{title}</MText>

      <CardsContainer>{children}</CardsContainer>
    </CardsSectionBox>
  )
}

const CardsSectionBox = styled.section`
  display: grid;
  gap: ${spacing.L};
`
const CardsContainer = styled.div`
  display: grid;
  gap: ${spacing.M};
`
