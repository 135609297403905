import { PresetSection } from "src/components/SettingsProfiles/MonitoringPresets/MonitoringPresetDialogs/PresetSection"
import { useTranslate } from "src/i18n/useTranslate"
import { MSelect } from "src/ui/MSelect/MSelect"

export function NoiseDurationSection({
  value,
  setValue,
}: {
  value: number
  setValue: (v: number) => void
}) {
  const { t, langKeys, tPlaceholder } = useTranslate()
  const minutesStr = (num: number) => tPlaceholder(`${num} minutes`)

  return (
    <PresetSection
      title={t(langKeys.device_settings_threshold_sound_duration_title)}
      description={tPlaceholder(
        `Select how long the noise should last before it triggers an alert.`
      )}
    >
      <MSelect
        required
        label={t(langKeys.device_settings_threshold_sound_duration_title)}
        value={String(value)}
        options={[
          { label: minutesStr(5), value: String(5 * 60) },
          { label: minutesStr(10), value: String(10 * 60) },
          { label: minutesStr(15), value: String(15 * 60) },
        ]}
        onChange={(value) => setValue(Number(value))}
      />
    </PresetSection>
  )
}
