import { PresetSection } from "src/components/SettingsProfiles/MonitoringPresets/MonitoringPresetDialogs/PresetSection"
import { useTranslate } from "src/i18n/useTranslate"
import { MTextField } from "src/ui/MTextField/MTextField"

export function NoiseThresholdSection({
  indoorValue,
  setIndoorValue,
  outdoorValue,
  setOutdoorValue,
}: {
  indoorValue: number
  setIndoorValue: (v: number) => void
  outdoorValue: number
  setOutdoorValue: (v: number) => void
}) {
  const { t, langKeys, tPlaceholder } = useTranslate()

  return (
    <PresetSection
      title={t(langKeys.noise_thresholds)}
      description={t(langKeys.noise_monitoring_short_description)}
    >
      <MTextField
        label={tPlaceholder("Indoor noise (dB)")}
        type="number"
        value={String(indoorValue)}
        onChange={(v) => setIndoorValue(Number(v))}
      />

      <MTextField
        label={tPlaceholder("Outdoor noise (dB)")}
        type="number"
        value={String(outdoorValue)}
        onChange={(v) => setOutdoorValue(Number(v))}
      />
    </PresetSection>
  )
}
