import styled from "styled-components"

import { PresetSectionToggle } from "src/components/SettingsProfiles/MonitoringPresets/MonitoringPresetDialogs/PresetSectionToggle"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { MSelect } from "src/ui/MSelect/MSelect"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"
import { formatTimeString } from "src/utils/l10n"

export function QuietHoursSection({
  enabled,
  setEnabled,

  startTime,
  setStartTime,
  endTime,
  setEndTime,

  indoorThreshold,
  setIndoorThreshold,
  outdoorThreshold,
  setOutdoorThreshold,
}: {
  enabled: boolean
  setEnabled: (v: boolean) => void

  startTime: string
  setStartTime: (v: string) => void
  endTime: string
  setEndTime: (v: string) => void

  indoorThreshold: number
  setIndoorThreshold: (v: number) => void
  outdoorThreshold: number
  setOutdoorThreshold: (v: number) => void
}) {
  const { t, langKeys, tPlaceholder } = useTranslate()
  const { clock_type } = useGetUser()

  function translateTime(time: (typeof availableTimes)[number]) {
    return formatTimeString(time, clock_type)
  }

  return (
    <PresetSectionToggle
      title={t(langKeys.quiet_hours)}
      state={enabled}
      setState={setEnabled}
      infoTooltipContents={tPlaceholder(
        `Enable if you want to set different thresholds for parts of the
            day`
      )}
    >
      <QuietHoursToggleContent>
        <MSelect
          required
          label={t(langKeys.start_time)}
          value={startTime}
          onChange={setStartTime}
          options={availableTimes.map((time) => ({
            label: translateTime(time),
            value: time,
          }))}
        />
        <MSelect
          required
          label={tPlaceholder("End time")}
          value={endTime}
          onChange={setEndTime}
          options={availableTimes.map((time) => ({
            label: translateTime(time),
            value: time,
          }))}
        />
      </QuietHoursToggleContent>

      <MTextField
        label={tPlaceholder("Indoor noise (dB)")}
        type="number"
        value={String(indoorThreshold)}
        onChange={(v) => setIndoorThreshold(Number(v))}
      />

      <MTextField
        label={tPlaceholder("Outdoor noise (dB)")}
        type="number"
        value={String(outdoorThreshold)}
        onChange={(v) => setOutdoorThreshold(Number(v))}
      />
    </PresetSectionToggle>
  )
}

const QuietHoursToggleContent = styled.div`
  display: flex;
  gap: ${spacing.M};
  flex-wrap: wrap;
  & > * {
    flex: 1 0 200px;
  }
`

const availableTimes = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
] as const
