import { PresetSettingCard } from "src/components/SettingsProfiles/MonitoringPresets/MonitoringPresetCards/PresetSettingCard"
import {
  NoiseAlertCAUpgradeDisclaimer,
  NoiseAlertSettingCardDescription,
  NoiseAlertSettingCardTitle,
} from "src/components/SettingsProfiles/NoiseAlertSettingCard"
import {
  usePostTrackNoiseSettingManagedEvent,
  variantToAlertLevel,
} from "src/data/analytics/queries/settingsAnalyticsQueries"
import { useGetCallAssistActive } from "src/data/callAssist/queries/callAssistQueries"
import { TNoiseAlertVariant } from "src/data/profileSettings/noiseAlertTypes"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"

export function PresetSettingCardNoiseAlert({
  variant,
}: {
  variant: TNoiseAlertVariant
}) {
  const { t, langKeys } = useTranslate()
  const { debug } = useFlags()

  // TODO MON-891: Base settings on new profile settings
  const settings = useNoiseAlertSettings()

  const { callAssistIsActive } = useGetCallAssistActive()
  const showCallAssistUpgrade = variant === "third_alert" && !callAssistIsActive
  const showCallAssistPill = variant === "third_alert" && callAssistIsActive

  const manageTrackingEvent = usePostTrackNoiseSettingManagedEvent()
  function handleManageClick() {
    manageTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
    })

    alert("IMPLEMENT ME")
  }

  // TODO MON-891: Remove debug-suffix
  const suffix = debug ? "New" : ""
  return (
    <PresetSettingCard
      title={<NoiseAlertSettingCardTitle variant={variant} suffix={suffix} />}
      description={<NoiseAlertSettingCardDescription variant={variant} />}
      onManageClick={handleManageClick}
      labeledPills={[
        {
          label: t(langKeys.settings_noise_alerts_sms_title),
          id: "sms",
          isOn: settings.smsEnabled,
        },
        {
          label: t(langKeys.settings_noise_alerts_autocall_title),
          id: "autocall",
          isOn: settings.autocallEnabled,
        },
        {
          label: t(langKeys.settings_noise_alerts_flash_and_sound_title),
          id: "flash",
          isOn: settings.flashEnabled,
        },
        {
          label: t(langKeys.call_assist),
          id: "call-assist",
          isOn: settings.callAssistEnabled ?? false,
          hidden: !showCallAssistPill,
        },
      ]}
    >
      {showCallAssistUpgrade && <NoiseAlertCAUpgradeDisclaimer />}
    </PresetSettingCard>
  )
}

function useNoiseAlertSettings() {
  return {
    smsEnabled: true,
    autocallEnabled: true,
    flashEnabled: false,
    callAssistEnabled: true,
  }
}
